<template>
  <v-container>
    <div class="display-1 mb-3">Our Memories</div>
    <div class="life__at">
      <div v-for="(img, i) in images" :key="i" class="life__at__img" data-aos="zoom-in">
        <img :src="img.image" :alt="img.title">
      </div>  
    </div>
  </v-container>  
</template>

<script>
export default {
  computed: {
    images() { return this.$store.state.images }
  }
}
</script>